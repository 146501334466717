import HYRequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'
import { Toast } from 'vant'
import store from '@/store'

let loading: any
const https = new HYRequest({
  // baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptors: (config) => {
      if (!config) {
        config = {}
      }

      if (!config.headers) {
        config.headers = {}
      }

      console.log(config)
      config.headers['Authorization'] = store.state.token
      console.log(config.headers['Authorization'])
      if (config.showLoading === true) {
        loading = Toast.loading({
          message: '加载中...',
          forbidClick: true
        })
      }
      return config
    },
    requestInterceptorsCatch: (err) => {
      console.log(err)
      return err
    },
    reponseInterceptors: (res) => {
      loading?.clear()
      return res.data
    },
    reponseInterceptorsCatch: (err) => {
      loading?.clear()
      return err
    }
  }
})

export default https
