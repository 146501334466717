import axios from 'axios'
import type { AxiosInstance } from 'axios'
import type { HYRequestConfig, HYRequestInterceptors } from './type'
import { Toast } from 'vant'
const isLoading = true
class HYRequest {
  instance: AxiosInstance
  interceptors?: HYRequestInterceptors
  showLoading?: boolean
  loading?: any
  // 为了扩展一个类型  interceptors
  constructor(config: HYRequestConfig) {
    this.instance = axios.create(config)
    this.interceptors = config.interceptors
    // 从config 中取出的拦截器是对应的实例的拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptors,
      this.interceptors?.requestInterceptorsCatch
    )

    this.instance.interceptors.response.use(
      this.interceptors?.reponseInterceptors,
      this.interceptors?.reponseInterceptorsCatch
    )

    // // 添加所有的实例都有的拦截器
    // this.instance.interceptors.request.use(
    //   (config: HYRequestConfig) => {
    //     this.showLoading = config.showLoading ?? isLoading
    //     if (this.showLoading === true) {
    //       this.loading = Toast.loading({
    //         message: '加载中...',
    //         forbidClick: true
    //       })
    //       console.log('加载loading', this.loading)
    //       console.log('加载loading', this.loading)
    //     }
    //     console.log(config, '所有的实例都有的拦截器：请求成功拦截、方法2')
    //     return config
    //   },
    //   (err) => {
    //     console.log(err, '所有的实例都有的拦截器：请求失败拦截、方法2')
    //     return err
    //   }
    // )
    // this.instance.interceptors.response.use(
    //   (res) => {
    //     this.loading?.close()
    //     this.showLoading = isLoading
    //     console.log(res.data, '所有的实例都有的拦截器：响应成功拦截、方法2')
    //     return res.data
    //   },
    //   (err) => {
    //     this.loading?.close()
    //     this.showLoading = isLoading
    //     console.log(err, '所有的实例都有的拦截器：响应失败拦截、方法2')
    //     return err
    //   }
    // )
  }

  request<T>(config: HYRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      this.instance
        .request<any, T>(config)
        .then((res) => {
          if (config.interceptors?.reponseInterceptors) {
            res = config.interceptors.reponseInterceptors(res)
          }
          console.log(res)
          resolve(res)
          this.showLoading = isLoading
        })
        .catch((err) => {
          this.showLoading = isLoading
          reject(err)
          return err
        })
    })
  }
  get<T>(config: HYRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'GET' })
  }
  post<T>(config: HYRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' })
  }
  delete<T>(config: HYRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' })
  }
  patch<T>(config: HYRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PATCH' })
  }
}

export default HYRequest
